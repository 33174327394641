/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import ReactGA from "react-ga";
import $ from "jquery";
import * as React from "react";
import { PreviewStoreProvider } from "gatsby-source-prismic";

ReactGA.initialize("UA-92628920-2");

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider initialEnabled={true}>{element}</PreviewStoreProvider>
);
